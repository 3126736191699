let ENDPOINT;
let env = process.env.REACT_APP_ENV;

if (env === "production") {
    ENDPOINT = process.env.REACT_APP_PRODUCTION_ENDPOINT;
} else if(env === "development") {
    ENDPOINT = process.env.REACT_APP_DEVELOPMENT_ENDPOINT;
} else if (env === "local"){
    ENDPOINT = process.env.REACT_APP_LOCAL_ENDPOINT;
}

export const API_ENDPOINT = ENDPOINT;
