import React, { useEffect, useState, useContext } from "react";
import { Badge, Menu, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import { setAllPermissions } from "../../appRedux/actions/Categories";
import { getRoleById } from "../../dispatchAPI";
import { GlobalDataContext } from "../../context/GlobalContext";

import {
  DASHBOARD_ACCESS,
  CATEGORY_ACCESS,
  ROLE_ACCESS,
  USER_ACCESS,
  PRODUCT_ACCESS,
  COUPON_ACCESS,
  OFFICE_SUPPLIES_ACCESS,
  ADDRESS_BOOK_ACCESS,
  VENDOR_ACCESS,
  ORDER_ACCESS,
  STORE_LOCATION_ACCESS,
  REVIEW_ACCESS,
  OUR_PARTNER_ACCESS,
  PURCHASE_ORDER_ACCESS,
} from "../../constants/ActionTypes";

import { useSelector, useDispatch } from "react-redux";
import { UnorderedListOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

const rootSubmenuKeys = ["userManagement", "orders"];

const SidebarContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [allPermissions, setSelectedPermissions] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [successOrErr, setSuccessOrErr] = useState("success");
  const globalContext = useContext(GlobalDataContext);

  useEffect(() => {
    fetchRolesById(localStorage.getItem("role_id"));
  }, []);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 500);
    }
  });

  const fetchRolesById = async (id) => {
    const roles = await getRoleById(id);
    let selectedOne = roles.permissions.filter((elem) => elem.is_selected);
    let selectedOneArr = selectedOne.map((elem) => elem.title);
    dispatch(setAllPermissions(selectedOneArr));
    setSelectedPermissions(selectedOneArr);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  let { navStyle, pathname } = useSelector(({ settings }) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const showAccessError = (columnName) => {
    setTextMessage(`You do not have the access to ${columnName}`);
    setSuccessOrErr("error");
    setShowMessage(true);
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        ></div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultSelectedKeys={[defaultOpenKeys]}
            defaultOpenKeys={[selectedKeys]}
            selectedKeys={[selectedKeys]}
            onOpenChange={onOpenChange}
            theme={"lite"}
            style={{ backgroundColor: "#E3E2E2", color: "green !important" }}
            mode="inline"
          >
            <Menu.Item
              key="dashboard"
              onClick={() => {
                if (allPermissions.includes(DASHBOARD_ACCESS)) {
                  history.push("/dashboard");
                } else {
                  showAccessError("Dashboard");
                }
              }}
            >
              <UnorderedListOutlined />
              <span>
                <IntlMessages id="Dashboard" />
              </span>
            </Menu.Item>
            <SubMenu
              key="orders"
              icon={
                <img src={require("assets/images/orders/orders.png")} alt="" />
              }
              title="Orders"
            >
              <Menu.Item key="newOrders">
                <Link to="/newOrders">New Orders</Link>
              </Menu.Item>
              <Menu.Item
                key="ordersList"
                onClick={() => {
                  if (allPermissions.includes(ORDER_ACCESS)) {
                    history.push("/ordersList");
                  } else {
                    showAccessError("Orders Lists");
                  }
                }}
              >
                Orders Lists
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="userManagement"
              icon={
                <img
                  src={require("assets/images/sidebar/user-management.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              title="User Management"
            >
              <Menu.Item
                key="user"
                onClick={() => {
                  if (allPermissions.includes(USER_ACCESS)) {
                    history.push("/user");
                  } else {
                    showAccessError("User");
                  }
                }}
              >
                User
              </Menu.Item>
              <Menu.Item
                key="roles$Permissions"
                onClick={() => {
                  if (allPermissions.includes(ROLE_ACCESS)) {
                    history.push("/roles$Permissions");
                  } else {
                    showAccessError("Roles & Permissions");
                  }
                }}
              >
                Roles & Permissions
              </Menu.Item>
              <Menu.Item
                key="addressBook"
                onClick={() => {
                  if (allPermissions.includes(ADDRESS_BOOK_ACCESS)) {
                    history.push("/addressBook");
                  } else {
                    showAccessError("Address Book");
                  }
                }}
              >
                Address Book
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="vendors"
              icon={
                <img
                  src={require("assets/images/sidebar/vendors.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(VENDOR_ACCESS)) {
                  history.push("/vendors");
                } else {
                  showAccessError("Vendors");
                }
              }}
            >
              <span>
                <IntlMessages id="Vendors" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="partners"
              icon={
                <img
                  src={require("assets/images/sidebar/partners.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(OUR_PARTNER_ACCESS)) {
                  history.push("/partners");
                } else {
                  showAccessError("Partners");
                }
              }}
            >
              <span>
                <IntlMessages id="Partners" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="categories"
              icon={
                <img
                  src={require("assets/images/sidebar/categories.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(CATEGORY_ACCESS)) {
                  history.push("/categories");
                } else {
                  showAccessError("Categories");
                }
              }}
            >
              <span>
                <IntlMessages id="Categories" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="variations"
              icon={
                <img
                  src={require("assets/images/sidebar/variations.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(CATEGORY_ACCESS)) {
                  history.push("/variations");
                } else {
                  showAccessError("Variations");
                }
              }}
            >
              <span>
                <IntlMessages id="Variations" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="contactus"
              icon={
                <img
                  src={require("assets/images/sidebar/contact-us.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(CATEGORY_ACCESS)) {
                  history.push("/contactus");
                } else {
                  showAccessError("Contact Us");
                }
              }}
            >
              <span>
                <IntlMessages id="Contact Us" />{" "}
                <Badge count={globalContext.contactQueriesCount} />
              </span>
            </Menu.Item>
            <Menu.Item
              key="productModules"
              icon={
                <img
                  src={require("assets/images/sidebar/products.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(PRODUCT_ACCESS)) {
                  localStorage.setItem("fetchingStatus", true);
                  history.push("/productModules");
                } else {
                  showAccessError("Products");
                }
              }}
            >
              <span>
                <IntlMessages id="sidebar.productsModule" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="coupons"
              icon={
                <img
                  src={require("assets/images/sidebar/coupons.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(COUPON_ACCESS)) {
                  history.push("/coupons");
                } else {
                  showAccessError("Coupons");
                }
              }}
            >
              <span>
                <IntlMessages id="Coupons" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="office-products"
              icon={
                <img
                  src={require("assets/images/sidebar/shop-orders.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(OFFICE_SUPPLIES_ACCESS)) {
                  history.push("/shop-orders");
                } else {
                  showAccessError("Shop Orders");
                }
              }}
            >
              <span>
                <IntlMessages id="Shop Orders" />
              </span>
            </Menu.Item>

            <Menu.Item
              key="reviews"
              icon={
                <img
                  src={require("assets/images/sidebar/reviews.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(REVIEW_ACCESS)) {
                  history.push("/reviews");
                } else {
                  showAccessError("Reviews");
                }
              }}
            >
              <span>
                <IntlMessages id="Reviews" />
              </span>
            </Menu.Item>

            <Menu.Item
              key="purchaseOrder"
              icon={
                <img
                  src={require("assets/images/sidebar/purchase-order.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(PURCHASE_ORDER_ACCESS)) {
                  history.push("/purchaseOrder");
                } else {
                  showAccessError("Purchase Order");
                }
              }}
            >
              <span>
                <IntlMessages id="Purchase Order" />
              </span>
            </Menu.Item>

            <Menu.Item
              key="stateSalesTax"
              icon={
                <img
                  src={require("assets/images/sidebar/state-sale-tax.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(PURCHASE_ORDER_ACCESS)) {
                  history.push("/stateSalesTax");
                } else {
                  showAccessError("State Sales Tax");
                }
              }}
            >
              <span>
                <IntlMessages id="State Sales Tax" />
              </span>
            </Menu.Item>

            <SubMenu
              key="locations"
              icon={
                <img
                  src={require("assets/images/orders/orders.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              title="Locations"
            >
              <Menu.Item
                key="storeLocation"
                onClick={() => {
                  if (allPermissions.includes(STORE_LOCATION_ACCESS)) {
                    history.push("/storeLocation");
                  } else {
                    showAccessError("Store Location");
                  }
                }}
              >
                Store Location
              </Menu.Item>

              <Menu.Item
                key="stockLocation"
                onClick={() => {
                  if (allPermissions.includes(ADDRESS_BOOK_ACCESS)) {
                    history.push("/stockLocation");
                  } else {
                    showAccessError("Stock Locations");
                  }
                }}
              >
                Stock Locations
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="reports"
              icon={
                <img
                  src={require("assets/images/productsModule/productsModule.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              title="Reports"
            >
              <Menu.Item
                key="topSellingItemsReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/top-selling-items");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Top Selling Items</span>
              </Menu.Item>
              <Menu.Item
                key="topSalesComparisonReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-comparison");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Comparison</span>
              </Menu.Item>
              <Menu.Item
                key="salesTaxReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-tax");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Tax</span>
              </Menu.Item>
              <Menu.Item
                key="jobsCompletedReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/jobs-completed");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Jobs Completed</span>
              </Menu.Item>
              <Menu.Item
                key="salesTaxExemptionReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-tax-exemption");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Tax Exemption</span>
              </Menu.Item>
              <Menu.Item
                key="salesTaxSummarizationReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-tax-summarization");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Tax Summarization</span>
              </Menu.Item>
              <Menu.Item
                key="outstandingOrdersReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/outstanding-orders");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Outstanding Orders</span>
              </Menu.Item>
              <Menu.Item
                key="paymentTransactionsReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/payment-transactions");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Payment Transactions</span>
              </Menu.Item>
              <Menu.Item
                key="orderErrorReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/order-error-report");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Order Error Report</span>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="settings"
              icon={
                <img
                  src={require("assets/images/sidebar/settings.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
            >
              <Link to="/settings">
                <span>
                  <IntlMessages id="sidebar.settings" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        {showMessage ? message[successOrErr](textMessage) : null}
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
