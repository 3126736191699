import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import rootSaga from "../sagas/index";
import createRootReducer from "../reducers";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage, // define which storage to use
  // whitelist: ['categories'] // Uncomment if you need to whitelist specific reducers
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(...middlewares) // Middleware applied here
    )
  );

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
}
